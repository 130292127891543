import ObjetivosServices from "./services";

import objetivoConstants from "./constants";

import store from "../rootStore";
import { getObjetivoPrevision } from "../global/actions";

const {
  SET_OBJETIVOS,
  SET_LOADING_OBJETIVOS,
  SET_SELECTED_ROW_KEYS,
  SET_SAVING_OBJETIVOS,
  SET_FETCHED_USER,
} = objetivoConstants;

export const setLoadingObjetivos = (isLoadingObjetivos: boolean) => ({
  type: SET_LOADING_OBJETIVOS,
  isLoadingObjetivos,
});

const setObjetivos = (objetivos: any) => ({
  type: SET_OBJETIVOS,
  objetivos,
});

export const fetchAll = () => {
  return (dispatch: any) => {
    dispatch(setLoadingObjetivos(true));
    let { Filters, ResumenGlobal } = store.getState();
    let anyHandler: any = ResumenGlobal;
    ObjetivosServices.fetchAll(
      {
        starts: anyHandler.date.starts.format("YYYY-MM-DDTHH:mm:ss[Z]"),
        ends: anyHandler.date.ends.format("YYYY-MM-DDTHH:mm:ss[Z]"),
        last: anyHandler.lastDates,
      },
      Filters
    ).then((res) => {
      dispatch(setLoadingObjetivos(false));
      return dispatch(setObjetivos(res.data));
    });
  };
};

const { SET_EDITING_OBJETIVOS, SET_EDIT_ERROR, SET_EDIT_OK } =
  objetivoConstants;

const setEditError = (editError: string | null) => ({
  type: SET_EDIT_ERROR,
  editError,
});

const setEditOk = (editOk: boolean) => ({
  type: SET_EDIT_OK,
  editOk,
});

export const edit = (data: any) => {
  return (dispatch: any) => {
    dispatch(setLoadingObjetivos(true));
    dispatch(setEditError(null));
    ObjetivosServices.edit(
      data.id,
      data.Centro,
      data.user,

      data.fecha_fin,
      data.fecha_inicio,

      data.cantidad,
      data.observaciones
    )
      .then((res: any) => {
        dispatch(setLoadingObjetivos(false));
        dispatch(setEditOk(true));
        dispatch(fetchAll());
        dispatch(getObjetivoPrevision());
      })
      .catch((err) => {
        if (
          // Messy
          err.response &&
          err.response.status !== 500 &&
          err.response.data &&
          err.response.data.errors.message
        ) {
          dispatch(setEditError(err.response.data.errors.message));
        } else {
          dispatch(setEditError("Ha ocurrido un error"));
        }
        dispatch(setLoadingObjetivos(false));
      });
  };
};

export const deleteObj = (data: any) => {
  return (dispatch: any) => {
    ObjetivosServices.delete(data)
      .then((res) => {
        let { message } = res.data;

        if (message === "deleted succesfully") {
          dispatch(getObjetivoPrevision());
          dispatch(fetchAll());
        }
      })
      .catch((e) => console.log(e));
  };
};

const selecteddRowKeys = (selectedRowKeys: any) => ({
  type: SET_SELECTED_ROW_KEYS,
  selectedRowKeys,
});

export const setSelectedRowKeys = (selectedRowKeys: any) => {
  return (dispatch: any) => {
    return dispatch(selecteddRowKeys(selectedRowKeys));
  };
};

const savingObjetivos = (saving: boolean | null) => ({
  type: SET_SAVING_OBJETIVOS,
  saving,
});

export const saveObjetivos = (data: any) => {
  return (dispatch: any) => {
    try {
      dispatch(setLoadingObjetivos(true));
      dispatch(savingObjetivos(true));
      ObjetivosServices.save(
        data.Centro,
        data.user,

        data.fecha_fin,
        data.fecha_inicio,

        data.cantidad,
        data.observaciones
      ).then((res) => {
        dispatch(setLoadingObjetivos(false));
        dispatch(savingObjetivos(true));
        dispatch(fetchAll());
        dispatch(getObjetivoPrevision());
      });
    } catch (e) {
      console.log(e);
    }
  };
};

export const setUser = (fetchedUser: any) => ({
  type: SET_FETCHED_USER,
  fetchedUser,
});

export const fetchCentroRelatedUser = (id: any) => {
  return (dispatch: any) => {
    try {
      ObjetivosServices.fetchUsersWhereCentro(id).then((res) => {
        dispatch(setUser(res.data));
      });
    } catch (e) {
      console.log(e);
    }
  };
};

export const fetchUsers = () => {
  return (dispatch: any) => {
    ObjetivosServices.fetchUserLists();
  };
};
