import React, {useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'

import {Table} from 'antd'
import formatToEUR from '../../../../../../helpers/formatToEuro'

import GraphsData from '../../global/component/graphs/'

import {
  getEstadisticasDoctores,
} from '../../../../../../redux/global/actions';


interface Props {
    pageSize: any
    data: any
    loading: any
}

export default function ResumenAsesores(props: Props) {
    let {pageSize, loading, data} = props

  
  const filters = useSelector ( (state: any)=> state.Filters)
  const { date, lastDates} = useSelector((state: any) => state.ResumenGlobal);
  const dispatch = useDispatch()

  useEffect( ()=>{
    dispatch(getEstadisticasDoctores({...date, last: lastDates, filters}))
  },[date, lastDates, filters])

  useEffect( ()=>{
    dispatch(getEstadisticasDoctores({...date, last: lastDates, filters}))
  },[])

  return (
        <div style={{padding:'1em', background:'white',display: 'grid', gridTemplateColumns: 'repeat(auto-fill, 48%)', gridGap: '2em'}}>
            <GraphsData data={data.derivaciones} label='DERIVACIONES POR ESPECIALIDAD' circular={false}/>
            <GraphsData data={data.aceptacionPorEspecialidad} label='DERIVACIONES POR ESPECIALIDAD' circular={false}/>
        </div>
    )
}
