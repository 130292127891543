import api from "../../helpers/api";

const globalServices = {
    fetchResumen: (
        dateStart: any,
        dateFinish: any,
        last: any,
        filters: any
    )=>{
        return api.post('/admin/global/resumen', {
            dateStart: dateStart.format('YYYY-MM-DDTHH:mm:ss[Z]'),
            dateFinish: dateFinish.format('YYYY-MM-DDTHH:mm:ss[Z]'),
            last,
            filters
        })
    },
    fetchObjetivos: (
        dateStart: any,
        dateFinish: any,
        last: any,
        filters: any
    )=>{
        return api.post('/admin/objetivos/header', {
            dateStart,
            dateFinish,
            last,
            filters
        })
    },
    fetchResumenAsesores: (
        dateStart: any,
        dateFinish: any,
        last: any,
        filters: any
    )=>{
        return api.post('/admin/resumen/asesores', {
            dateStart,
            dateFinish,
            last,
            filters
        })
    },
    fetchIndicadorAsesores: (
        dateStart: any,
        dateFinish: any,
        last: any,
        filters: any
    )=>{
        return api.post('/admin/indicadores/asesores', {
            dateStart,
            dateFinish,
            last,
            filters
        })
    },
    fetchResumenDoctores: (
        dateStart: any,
        dateFinish: any,
        last: any,
        filters: any
    )=>{
        return api.post('/admin/resumen/doctores', {
            dateStart,
            dateFinish,
            last,
            filters
        })
    },
    fetchTipologiaDoctores: (
        dateStart: any,
        dateFinish: any,
        last: any,
        filters: any
    )=>{
        return api.post('/admin/tipologia/doctores', {
            dateStart,
            dateFinish,
            last,
            filters
        })
    },
    fetchDerivacionesDoctores: (
        dateStart: any,
        dateFinish: any,
        last: any,
        filters: any
    )=>{
        return api.post('/admin/derivaciones/doctores', {
            dateStart,
            dateFinish,
            last,
            filters
        })
    },
    fetchDerivacionesEfectivasDoctores:  (
        dateStart: any,
        dateFinish: any,
        last: any,
        filters: any
    )=>{
        return api.post('/admin/derivaciones-efectivas/doctores', {
            dateStart,
            dateFinish,
            last,
            filters
        })
    },
    fetchTotalizadorDoctores: (
        dateStart: any,
        dateFinish: any,
        last: any,
        filters: any
    )=>{
        return api.post('/admin/totalizador/doctores', {
            dateStart,
            dateFinish,
            last,
            filters
        })
    },
    fetchEstadisticasDoctores: (
        dateStart: any,
        dateFinish: any,
        last: any,
        filters: any
    )=>{
        return api.post('/admin/estadisticas/doctores', {
            dateStart,
            dateFinish,
            last,
            filters
        })
    },
    fetchObjetivoPrevision:  (
        dateStart: any,
        dateFinish: any,
        last: any,
        filters: any
    )=>{
        return api.post('/admin/objetivo-prevision', {
            dateStart,
            dateFinish,
            last,
            filters
        })
    },
    fetchEstadisticasAsesores: (
        dateStart: any,
        dateFinish: any,
        last: any,
        filters: any
    )=>{
        return api.post('/admin/estadisticas/asesores', {
            dateStart,
            dateFinish,
            last,
            filters
        })
    },
    getNotifications: (
        dates: any,
        filters: any
    )=>{
        return api.post('/admin/notification/parte_presupuesto', {
            dates,
            filters
        })
    }
}

export default globalServices