import React, {useState, useEffect} from 'react'
import {Checkbox} from 'antd'


export default function Filter(props: any) {
    let {handleClick, withoutOrange} = props

    const [checkedData, setCheckedData] = useState({
        rojas: true,
        verdes: true,
        amarillo: true,
        blancas: true
    })
    

    const handleChange = (name:any, value: any)=>{
        let {checked} = value.target
        setCheckedData({...checkedData, [name]:checked})
    }

    useEffect( ()=>{
        handleClick(checkedData)
    },[checkedData])
    
    return (
        <div style={{position:'relative', padding: '0 1em', display: 'grid', gridGap: 4, fontSize: 18}}>
            <span style={{position: 'absolute', padding: '0 1em', top: -30, zIndex: 1}}>
                Filtrar por líneas:
            </span>
            <text>
                <Checkbox
                    checked={checkedData.rojas}
                    onChange={
                        (value:any)=>handleChange('rojas', value)
                    }
                    >
                    <span style={{fontSize: 18}}>Rojas</span> 
                </Checkbox>
                
                <Checkbox 
                    checked={checkedData.verdes}
                    onChange={
                        (value:any)=>handleChange('verdes', value)
                    }>
                    <span style={{fontSize: 18}}>Verdes</span> 
                </Checkbox>

                {!withoutOrange && <Checkbox 
                    checked={checkedData.amarillo}
                    onChange={
                        (value:any)=>handleChange('amarillo', value)
                    }>
                    <span style={{fontSize: 18}}>Amarillo</span> 
                </Checkbox>}

                <Checkbox 
                    checked={checkedData.blancas}
                    onChange={
                        (value:any)=>handleChange('blancas', value)
                    }>
                    <span style={{fontSize: 18}}>Blancas</span> 
                </Checkbox>
            </text>
        </div>
    )
}
